import React from "react";
import Footer from "../components/Footer/Footer";
import TeammateHeader from "../components/Headers/TeammateHeader/TeammateHeader";
import Navbar from "../components/Navbar/Navbar";

import Background from "../images/teammateBackground.png";
import BackgroundMobile from "../images/teammateBackgroundMobile.png";
import TeammatePhoto from "../images/MDowd_Photo.png";
import Teammate from "../components/Teammate/Teammate";

const teammatePage = () => {
  return (
    <div>
      <Navbar />
      <TeammateHeader
        Name="Matthew Dowd"
        Description="CEO and Founder"
        background={Background}
        backgroundMobile={BackgroundMobile}
        TeammatePhoto={TeammatePhoto}
      />
      <article className="w-[100%] h-auto flex items-center justify-center bg-white">
        <Teammate
          paragraph1="matthew founded intermeta in the summer of 2021 to revolutionize the value paradigm of digital assets in sight of the eminent metaverse industry. 
                      Certain of the importance of identity in the metaverse, Matthew believes in the power of ownership and how it will be closely tied to individuality and 
                      belonging in the digital world. With strong conviction that the value of digital assets could be equalized to that of physical products through reliable 
                      and complex interoperability, Matthew defined a new term to add to the lexicon of the metaverse industry: intermetability.
                      Matthew has continued to work on pushing the envelope of technology towards intermetability with intermeta’s development team, consistently guiding the company with clear vision."

          paragraph2="focusing on the implications of intermetability on gaming now, thinking about this technology’s applicability to education, remote work, and IoT is what 
                      drives Matthew to make intermetability available to every developer on the planet. "

          paragraph3="On the public front, Matthew works closely with project leader Cameron Andersen on making intermetability something that the world can get behind and excited for. 
                      Matthew also leads partnerships with other companies interested in leveraging intermetability for gaming, commerce, social, and industrial applications."
        />
      </article>
      <Footer />
    </div>
  );
};

export default teammatePage;
